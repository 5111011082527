import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiEdit, FiPlus } from "react-icons/fi";
import { getCompanies } from '../api/request';
import InfoBox from '../components/InfoBox';

function Admin(props) {
    const [companies, setCompanies] = useState(null);
    const [info, setInfo] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCompanies = async () => {
            try {
                const res = await getCompanies();
                if (res.status) {
                    setCompanies(res.companies);
                } else {
                    setInfo("Sayfa yüklenemedi. Tekrar deneyiniz.");
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchCompanies();
    }, []);

    const columns = ['Ad', "Aktif"];

    return (
        <div className='p-14'>
            <div className="headerBar">
                <p>Firmalar</p>
                <Link to='add-company' className='w-max h-max'>
                    <div className='btn-primary'>
                        <FiPlus className='w-5 h-5 mr-1' />
                        <p>Firma</p>
                    </div>
                </Link>
            </div>
            <div className='flex flex-col shadow-lg overflow-x-auto rounded'>
                <table>
                    <thead
                        className="border-b bg-white text-slate-700 font-medium text-sm">
                        <tr>
                            <th scope="col" className='pl-4'></th>
                            {columns.map((column, index) => (
                                <th scope="col" className="px-6 py-3 text-left" key={index}>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    {companies
                        ?
                        <tbody>
                            {companies.map((row, index) => (
                                <tr className={`border-b bg-white hover:bg-slate-100 text-sm`} key={index}>
                                    <td className="w-5 pl-6 py-4">
                                        <div className='flex flex-row'>
                                            <div className='mr-6'>
                                                <FiEdit
                                                    onClick={() => {
                                                        navigate('update-company', { state: { data: companies[index] } });
                                                    }}
                                                    className='w-4 
                                                    h-4 
                                                    text-emerald-400 
                                                    hover:cursor-pointer
                                                    hover:text-emerald-700' />
                                            </div>
                                        </div>

                                    </td>

                                    <td className="px-6 py-3 text-left">{row.name}</td>
                                    <td className="px-6 py-3 text-left">
                                        <input
                                            type="checkbox"
                                            checked={row.isActive}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        : ""}
                </table>
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default Admin;