import React, { useState, useEffect, useRef } from 'react';

const DropdownMenu = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.selection);
    const dropdownRef = useRef(null);
    const { selectedIndexNo, onToggle } = props || null;
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const isVisible = props.isVisible === undefined ? true : props.isVisible;

    const handleOptionSelect = (option, index) => {
        setSelectedOption(option);
        if (onToggle) {
            onToggle(option);
        }
        if (selectedIndexNo) {
            selectedIndexNo(index)
        }
        setIsOpen(false);
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
    }, []);

    const dropdownOptions = props.options;

    return (
        <div className="w-full relative inline-block text-left" ref={dropdownRef}>
            <button
                onClick={toggleDropdown}
                type="button"
                className={`inline-flex w-full px-4 py-2 text-sm font-medium text-left text-gray-700  
                    border border-gray-300 rounded-md hover:bg-gray-50 z-40 ${!isVisible ? "bg-gray-100" : "bg-white"}`}
                id="options-menu"
                aria-haspopup="true"
                aria-expanded="true"
            >
                {selectedOption || "Seçim yapın"}
            </button>

            {isOpen && isVisible ?
                <div
                    className="w-full absolute right-0 mt-0 space-y-2 origin-top-right bg-white border border-gray-300 
                        rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-20 max-h-52 overflow-y-auto"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    {dropdownOptions.map((option, index) => (
                        <button
                            key={index}
                            onClick={() => handleOptionSelect(option, index)}
                            className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                            role="menuitem"
                        >
                            {option}
                        </button>
                    ))}
                </div>
                : ""
            }
        </div>
    );
};

export default DropdownMenu;
