import { useEffect } from "react";
import { useDispatch } from "react-redux";

function Page404() {
  const title = "404"
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'CURRENTPAGE', value: title });
  }, [dispatch]);

  return (
    <div className="flex flex-col justify-center items-center p-10">
        <p className="font-bold text-3xl">404</p>
        <p>Sayfa Bulunamadı</p>
    </div>
  );
}

export default Page404;
