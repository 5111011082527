import { useState } from "react";
import DropdownMenu from "./DropdownMenu";

function FormLocation(props) {
    const [formData, setFormData] = useState(props.location);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const newLocation = formData;
        if (typeof newLocation.isActive === 'boolean') {
            props.onToggle(newLocation);
            return;
        }
        newLocation.isActive = newLocation.isActive === "Evet"; 
        props.onToggle(newLocation);
    };

    const handleIsActiveToggle = (newIsActive) => {
        setFormData({ ...formData, isActive: newIsActive });
    };

    return (
        <form
            onSubmit={handleSubmit}
            className='w-full flex flex-col'>
            <div className='w-full flex flex-col md:flex-row'>
                <div className='w-full md:w-80 mr-0 md:mr-5'>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Bölüm</label>
                        <input
                            type="text"
                            name="location"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.location}
                            required />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Aktif</label>
                        <DropdownMenu
                            selection={formData.isActive ? "Evet" : "Hayır"}
                            onToggle={handleIsActiveToggle}
                            options={["Evet", "Hayır"]} />
                    </div>
                </div>
            </div>
            <button
                type="submit"
                className='btn-secondary'>
                {props.buttonTitle}
            </button>
        </form>
    )
}

export default FormLocation;