import { useState } from "react";
import { postInsertLocation } from "../api/request";
import { FiChevronLeft } from "react-icons/fi";
import InfoBox from "../components/InfoBox";
import { useNavigate } from "react-router-dom";
import FormLocation from "../components/FormLocation";

function AddLocation() {
    const [info, setInfo] = useState(null);
    const navigate = useNavigate();

    const handleInsertLocation = async (newLocation) => {
        try {
            const res = await postInsertLocation(newLocation);
            if (res.status) {
                setInfo(res.message);
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className="headerBar">
                <p>Bölüm ekle</p>
                <div
                    onClick={() => {
                        navigate("/locations");
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Bölümler</p>
                </div>
            </div>
            <div className="w-full 
                        bg-white
                        shadow-lg
                        rounded
                        p-5">
                <FormLocation
                    buttonTitle={"Kaydet"}
                    onToggle={handleInsertLocation}
                    location={{
                        location: "",
                        isActive: true,
                    }} />
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default AddLocation;