import { useState } from "react";
import { postInsertCompanyUser, postUpdateCompany } from "../api/request";
import InfoBox from "../components/InfoBox";
import { FiChevronLeft } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import FormCompany from "../components/FormCompany";
import FormUser from "../components/FormUser";

function UpdateCompany() {
    const [info, setInfo] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state?.data;

    const handleUpdateCompany = async (company) => {
        try {
            const res = await postUpdateCompany(company);
            if (res.status) {
                setInfo(res.message);
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleInsertUser = async (user) => {
        user.companyID = data._id;

        if (user.password) {
            const passwordError = validatePassword(user.password);
            if (passwordError) {
                setInfo(passwordError);
                return;
            }
        }

        try {
            const res = await postInsertCompanyUser(user);
            if (res.status) {
                setInfo(res.message);
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const validatePassword = (password) => {
        const minLength = password.length >= 8;
        const hasUpperCase = /[A-Z]/.test(password);

        if (!minLength) {
            return "Şifre en az 8 karakter olmalıdır";
        }
        if (!hasUpperCase) {
            return "Şifre en az bir büyük harf içermelidir";
        }

        return "";
    };

    return (
        <div className="p-14">
            <div className="headerBar">
                <p>Firma güncelle</p>
                <div
                    onClick={() => {
                        navigate("/admin");
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Firmalar</p>
                </div>
            </div>

            <div className="w-full 
                        bg-white
                        shadow-lg
                        rounded
                        p-5">
                <FormCompany
                    buttonTitle={"Güncelle"}
                    onToggle={handleUpdateCompany}
                    company={data} />
                {
                    info ? <InfoBox content={info} /> : ""
                }

            </div>

            <div className="w-full 
                        bg-white
                        shadow-lg
                        rounded
                        p-5
                        mt-5">

                <FormUser
                    buttonTitle={"Kaydet"}
                    onToggle={handleInsertUser}
                    user={{
                        name: "",
                        surname: "",
                        email: "",
                        role: "Admin",
                        username: "",
                        isActive: true,
                        password: "",
                        authority: [],
                    }} />
            </div>
        </div>
    )
}

export default UpdateCompany;