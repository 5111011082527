import { useEffect, useState } from "react";
import DropdownMenu from "./DropdownMenu";
import { getLocations } from "../api/request";

function FormUser(props) {
    const [formData, setFormData] = useState(props.user);
    const [emailContent, setEmailContent] = useState(null);
    const [locations, setLocations] = useState(null);
    // eslint-disable-next-line
    const [userLocations, setUserLocations] = useState(props.user.authority);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const res = await getLocations();
                if (res.status) {
                    setLocations(res.locations.map(item => {
                        return {
                            ...item,
                            isActive: userLocations.filter(l => l === item._id).length > 0
                        }
                    }))
                }

            } catch (error) {
                console.log(error);
            }
        }
        fetchLocations();
    }, [userLocations]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!(/^[a-zA-Z0-9._-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/.test(formData.email))) {
            setEmailContent("E posta formatı hatalı");
            return;
        } else {
            setEmailContent(null);
        }
        const newUser = formData;

        var userSelectedLocations = locations.filter(item => item.isActive);
        userSelectedLocations = userSelectedLocations.map(item => item._id);

        if (typeof newUser.isActive === 'boolean') {
            props.onToggle(newUser, userSelectedLocations);
            return;
        }
        newUser.isActive = newUser.isActive === "Evet";

        props.onToggle(newUser, userSelectedLocations);
    };

    const handleRoleToggle = (newRole) => {
        setFormData({ ...formData, role: newRole });
    };

    const handleIsActiveToggle = (newIsActive) => {
        setFormData({ ...formData, isActive: newIsActive });
    };

    const handleCheckboxClick = (index) => {
        const updatedLocations = [...locations];
        updatedLocations[index].isActive = !updatedLocations[index].isActive;
        setLocations(updatedLocations);
    };

    const allCheckbox = () => {
        setLocations(locations.map(item => {
            return {
                ...item,
                isActive: true
            }
        }))
    }

    return (
        <form
            onSubmit={handleSubmit}
            className='w-full flex flex-col'>
            <div className='w-full flex flex-col md:flex-row'>
                <div className='w-full md:w-80 mr-0 md:mr-5'>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Ad</label>
                        <input
                            type="text"
                            name="name"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.name}
                            required />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Soyad</label>
                        <input
                            name="surname"
                            type="text"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.surname}
                            required />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Rol</label>
                        <DropdownMenu
                            selection={formData.role}
                            onToggle={handleRoleToggle}
                            options={["Admin", "User"]} />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Şifre</label>
                        <input
                            name="password"
                            type="password"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.password}
                            required />
                    </div>
                </div>
                <div className='w-full md:w-80 mr-0 md:mr-5'>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>
                            <p>
                                Kullanıcı Adı
                            </p>
                        </label>
                        <input
                            name="username"
                            type="text"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.username}
                            required />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1 flex flex-row items-center'>
                            <p>
                                E posta
                            </p>
                            {emailContent
                                ? <p className='font-medium text-sm text-red-400 ml-1'>({emailContent})</p>
                                : ""
                            }
                        </label>
                        <input
                            name="email"
                            type="text"
                            className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                            onChange={handleChange}
                            value={formData.email}
                            required />
                    </div>
                    <div className='flex flex-col mb-6'>
                        <label className='text-slate-600 font-medium mb-1'>Aktif</label>
                        <DropdownMenu
                            selection={formData.isActive ? "Evet" : "Hayır"}
                            onToggle={handleIsActiveToggle}
                            options={["Evet", "Hayır"]} />
                    </div>
                </div>
            </div>

            <label className='text-slate-600 font-medium mb-1'>Yetki</label>

            <p className="text-sm mb-2 cursor-pointer" onClick={allCheckbox}>Tümünü seç</p>

            <div className="md:w-80 w-full h-48 border border-gray-300 rounded-md py-2 text-gray-700 overflow-auto mb-5">
                {
                    locations &&
                    locations.map((location, index) => (

                        <div key={index} className={`my-1 flex flex-row hover:bg-gray-100 px-3 py-1 items-center cursor-pointer`} onClick={() => handleCheckboxClick(index)}>
                            <input
                                id={`locationCheckbox-${index}`}
                                className="mr-2 cursor-pointer"
                                type="checkbox"
                                checked={location.isActive}
                                onChange={() => handleCheckboxClick(index)}
                                onClick={(e) => e.stopPropagation()}
                            />
                            <label htmlFor={`locationCheckbox-${index}`} className="cursor-pointer" onClick={() => handleCheckboxClick(index)}>{location.location}</label>
                        </div>
                    ))
                }

            </div>

            <button
                type="submit"
                className='btn-secondary'>
                {props.buttonTitle}
            </button>
        </form>
    )
}

export default FormUser;