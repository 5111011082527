import { useState } from "react";
import { postUpdateLocation } from "../api/request";
import InfoBox from "../components/InfoBox";
import { FiChevronLeft } from "react-icons/fi";
import FormLocation from "./FormLocation";

function UpdateLocation({ location, pageIsUpdateLocation }) {
    const [info, setInfo] = useState(null);

    const handleUpdateLocation = async (location) => {
        try {
            const res = await postUpdateLocation(location);
            if (res.status) {
                setInfo(res.message);
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className="headerBar">
                <p>Bölüm güncelle</p>
                <div
                    onClick={() => {
                        pageIsUpdateLocation(false);
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Bölümler</p>
                </div>
            </div>

            <div className="w-full 
                        bg-white
                        shadow-lg
                        rounded
                        p-5">
                <FormLocation
                    buttonTitle={"Güncelle"}
                    onToggle={handleUpdateLocation}
                    location={location} />
                {
                    info ? <InfoBox content={info} /> : ""
                }

            </div>
        </div>
    )
}

export default UpdateLocation;