function InfoBox({ content }) {
    return (
        <div className="absolute 
                        bottom-10 
                        right-10
                        bg-blue-400
                        text-white
                        px-5
                        py-3
                        rounded-lg
                        shadow-lg">
            <p>{content}</p>
        </div>
    )
}

export default InfoBox;