import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import LocationsList from '../components/LocationList';
import UpdateLocation from '../components/UpdateLocation';

function Locations() {
    const title = "Bölümler"
    const dispatch = useDispatch();
    const [isUpdateLocation, setIsUpdateLocation] = useState(false);
    const [updateLocation, setUpdateLocation] = useState(false);

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: title });
    }, [dispatch]);

    const handlePageIsUpdateLocation = (state) => {
        setIsUpdateLocation(state);
    }

    const handleUpdateLocation = (location) => {
        setUpdateLocation(location);
    }

    return (
        <div>
            {
                isUpdateLocation
                    ? <UpdateLocation
                        location={updateLocation}
                        pageIsUpdateLocation={handlePageIsUpdateLocation} />
                    : <LocationsList
                        updateLocation={handleUpdateLocation}
                        pageIsUpdateLocation={handlePageIsUpdateLocation}
                    />
            }
        </div>
    )
}

export default Locations;