import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import EquipmentList from "../components/EquipmentList";
import UpdateEquipment from "../components/UpdateEquipment";

function Equipment() {
    const title = "Ekipmanlar"
    const dispatch = useDispatch();
    const [isUpdateEquipment, setIsUpdateEquipment] = useState(false);
    const [updateEquipment, setUpdateEquipment] = useState(false);

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: title });
    }, [dispatch]);


    const handlePageIsUpdateEquipment = (state) => {
        setIsUpdateEquipment(state);
    }

    const handleUpdateEquipment = (equipment) => {
        setUpdateEquipment(equipment);
    }

    return (
        <div>
            {
                isUpdateEquipment
                    ? <UpdateEquipment
                        equipment={updateEquipment}
                        pageIsUpdateEquipment={handlePageIsUpdateEquipment}  />
                    : <EquipmentList
                        updateEquipment={handleUpdateEquipment}
                        pageIsUpdateEquipment={handlePageIsUpdateEquipment} />
            }
        </div>
    )
}

export default Equipment;