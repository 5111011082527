import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiPlus } from "react-icons/fi";
import { getNumbers } from '../api/request';
import InfoBox from '../components/InfoBox';
import { useDispatch } from 'react-redux';

function Numbers() {
    const [numbers, setNumbers] = useState(null);
    const [info, setInfo] = useState(null);
    const title = "PCT"
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: title });
    }, [dispatch]);

    useEffect(() => {
        const fetchNumbers = async () => {
            try {
                const res = await getNumbers();
                if (res.status) {
                    setNumbers(res.numbers);
                } else {
                    setInfo("Sayfa yüklenemedi. Tekrar deneyiniz.");
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchNumbers();
    }, []);

    return (
        <div>
            <div className="headerBar">
                <p>Periyodik Kontrol Etiketi</p>
                <Link to='/pct/add-pct' className='w-max h-max'>
                    <div className='px-2 py-1 rounded-md bg-blue-500 hover:cursor-pointer hover:bg-blue-600 text-white flex flex-row items-center'>
                        <FiPlus className='w-5 h-5 mr-1' />
                        <p>PCT</p>
                    </div>
                </Link>
            </div>
            <div className='flex flex-col shadow-lg overflow-x-auto rounded'>
                <table>
                    <thead
                        className="border-b bg-white text-slate-700 font-medium text-sm">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left">{title}</th>
                        </tr>
                    </thead>
                    {numbers
                        ?
                        <tbody>
                            {numbers.map((row, index) => (
                                <tr className={`border-b bg-white hover:bg-slate-100 text-sm`} key={index}>
                                    <td className="px-6 py-3 text-left">{row.number}</td>
                                </tr>
                            ))}
                        </tbody>
                        : ""}
                </table>
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default Numbers;