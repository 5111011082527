import { makeRequest } from "./utils"

export const getUsers = () => {
    return makeRequest("get", "/users");
}

export const postInsertUser = (user) => {
    return makeRequest("post", "/users/insert-user", user);
}

export const postUpdateUser = (user) => {
    return makeRequest("post", "/users/update-user", user);
}

export const postUpdateLocation = (location) => {
    return makeRequest("post", "/locations/update-location", location);
}

export const getEquipments = (locationID, userID) => {
    return makeRequest("get", `/equipments/${locationID}/${userID}`);
}

export const getEquipmentControl = (number) => {
    return makeRequest("get", `/equipments/equipment-control/${number}`);
}

export const postInsertEquipment = (equipment) => {
    return makeRequest("post", "/equipments/insert-equipment", equipment);
}

export const postUpdateEquipment = (equipment) => {
    return makeRequest("post", "/equipments/update-equipment", equipment);
}

export const getData = (body) => {
    return makeRequest("post", "/data/get-data", body);
}

export const postInsertData = (data) => {
    return makeRequest("post", "/data/insert-data", data);
}

export const postUpdateData = (data) => {
    return makeRequest("post", "/data/update-data", data);
}

export const getFilesById = (dataID) => {
    return makeRequest("get", `/file/get-file-by-id?dataID=${dataID}`);
}

export const getFilesByEquipmentID = (equipmentID) => {
    return makeRequest("get", `/file/get-file-by-equipmentID?equipmentID=${equipmentID}`);
}

export const deleteFile = (dataID) => {
    return makeRequest("delete", `/file/delete-file/${dataID}`);
}

export const deleteUser = (userID) => {
    return makeRequest("delete", `/users/delete-user/${userID}`);
}

export const deleteData = (dataID) => {
    return makeRequest("delete", `/data/delete-data/${dataID}`);
}

export const deleteEquipment = (equipmentID) => {
    return makeRequest("delete", `/equipments/delete-equipment/${equipmentID}`);
}

export const getNumbers = () => {
    return makeRequest("get", "/numbers");
}

export const getNumbersWithoutRegistered = () => {
    return makeRequest("get", "/numbers//without-registered");
}

export const getNumbersWithRegistered = () => {
    return makeRequest("get", "/numbers//with-registered");
}

export const postInsertNumber = (number) => {
    return makeRequest("post", "/numbers/insert-number", number);
}

export const getLocations = (userID) => {
    return makeRequest("get", `/locations/${userID}`);
}

export const getDataByLocation = (locationID) => {
    return makeRequest("get", `/data/data-by-locationID/${locationID}`);
}

export const postInsertLocation = (location) => {
    return makeRequest("post", "/locations/insert-location", location);
}

export const getLogin = (user) => {
    return makeRequest("get", `/login?email=${user.email}&password=${user.password}`);
}

export const getSizeControl = (number) => {
    return makeRequest("get", `/data/size-control/${number}`);
}

export const getArchiveByNumber = (number) => {
    return makeRequest("get", `archive/archive-by-number/${number}`);
}

export const getCompanies = () => {
    return makeRequest("get", "company/list");
}

export const postInsertCompany = (company) => {
    return makeRequest("post", "company/insert", company);
}

export const postUpdateCompany = (company) => {
    return makeRequest("post", "company/update", company);
}

export const postInsertCompanyUser = (user) => {
    return makeRequest("post", "company/insert-user", user);
}