import axios from "axios";
import { URL } from "../utils/utils";
import Cookies from 'js-cookie';

const instance = axios.create({
    baseURL: `${URL}api/`,
    headers: { 'X-Custom-Header': 'foobar', 'Content-Type': 'application/json' }
});

instance.interceptors.request.use(
    config => {
        const token = Cookies.get('skysan-token');
        if (token) {
            config.headers['authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export const makeRequest = async (type, path, body) => {
    try {
        const response = await instance[type](path, body);
        return response.data;
    } catch (error) {
        console.log(error.response);
        return error.response;
    }
}