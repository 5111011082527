import { useEffect, useState } from "react";
import { FiChevronLeft } from "react-icons/fi";
import { getArchiveByNumber } from "../api/request";
import InfoBox from "./InfoBox";
import { URL } from "../utils/utils";
import PDFLogo from "../pdf.png";

function ArchiveDetail(props) {
    const [archive, setArchive] = useState([]);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        const getArchiveData = async () => {
            try {
                const res = await getArchiveByNumber(props.archive._id);
                if (res.status) {
                    setArchive(res.archive);
                } else {
                    setInfo(res.message);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getArchiveData();
    }, [props.archive]);

    return (
        <div>
            <div className="headerBar">
                <p>{props.archive.number}</p>
                <div
                    onClick={() => {
                        setArchive([]);
                        props.pageIsArchiveDetail(false);
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Arşiv</p>
                </div>

            </div>
            <div>
                {
                    archive.length > 0
                        ?
                        <div className="w-full flex flex-col bg-white shadow-lg rounded p-5">

                            {archive.map(item => (
                                <div>
                                    <p
                                        className="text-slate-700 font-bold mr-2 mt-2"
                                        key={item.date}>
                                        {item.date}
                                    </p>

                                    <div className="flex flex-row flex-wrap py-2">
                                        {
                                            item.filePath.length > 0
                                                ? item.filePath.map((file, index) => (
                                                    <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                        <div className="w-20 h-20">
                                                            <a href={`${URL}uploads/${file.path}`} target="blank">
                                                                <img
                                                                    key={index}
                                                                    src={PDFLogo}
                                                                    alt={`${index}`}
                                                                    className="w-20 h-20 cursor-pointer"
                                                                />
                                                            </a>
                                                        </div>

                                                        <p className="text-sm truncate flex items-center">{file.name}</p>
                                                    </div>
                                                ))

                                                : <p className="text-sm">
                                                    Belge Mevcut Değil
                                                </p>
                                        }
                                    </div>
                                    
                                    <hr className="py-2" />
                                </div>
                            ))}

                        </div>
                        :
                        <div className="headerBar">
                            Kayıt Mevcut Değil
                        </div>
                }
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default ArchiveDetail;