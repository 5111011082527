import React, { useState, useEffect, useRef } from 'react';
import { FaAngleDown } from "react-icons/fa6";

const InputDropdownMenu = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.selection);
    const dropdownRef = useRef(null);
    const { selectedIndexNo, onToggle } = props || null;
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const isVisible = props.isVisible === undefined ? true : props.isVisible;

    const handleOptionSelect = (option, index) => {
        setSelectedOption(option);
        if (onToggle) {
            onToggle(option);
        }
        if (selectedIndexNo) {
            selectedIndexNo(index)
        }
        setIsOpen(false);
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
    }, []);

    const dropdownOptions = props.options;

    const handleSelect = (e) => {
        onToggle(Number(e.target.value));
        setSelectedOption(e.target.value);
    }

    return (
        <div className="w-full relative inline-block text-left" ref={dropdownRef}>
            <div className='flex flex-row border-gray-300   w-full border border-gray-300 rounded-md
                                text-sm font-medium text-left text-gray-700 outline-none'>
                <input
                    type="number"
                    min={1}
                    className='w-full h-10 rounded-md px-3 py-4
                                text-sm font-medium text-left text-gray-700 outline-none'
                    name='selected'
                    onChange={handleSelect}
                    value={selectedOption} />
                <button
                    onClick={toggleDropdown}
                    type="button"
                    className={`w-min inline-flex w-full px-4 py-2 text-sm font-medium text-left text-gray-700  
                    rounded-md hover:bg-gray-200 z-40 items-center ${!isVisible ? "bg-gray-100" : "bg-white"}`}
                    id="options-menu"
                    aria-haspopup="true"
                    aria-expanded="true">
                    <FaAngleDown />
                </button>
            </div>


            {isOpen && isVisible ?
                <div
                    className="w-full absolute right-0 mt-0 space-y-2 origin-top-right bg-white border border-gray-300 
                        rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-20 max-h-52 overflow-y-auto"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    {dropdownOptions.map((option, index) => (
                        <button
                            key={index}
                            onClick={() => handleOptionSelect(option, index)}
                            className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                            role="menuitem"
                        >
                            {option}
                        </button>
                    ))}
                </div>
                : ""
            }
        </div>
    );
};

export default InputDropdownMenu;
