import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DataList from "../components/DataList";
import DataDetail from "../components/DataDetail";

function Data() {
    const title = "Kayıtlar"
    const dispatch = useDispatch();
    const [isDataDetail, setIsDataDetail] = useState(false);
    const [dataDetail, setDataDetail] = useState(false);

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: title });
    }, [dispatch]);

    const handlePageIsDataDetail = (state) => {
        setIsDataDetail(state);
    }

    const handleDataDetail = (data) => {
        setDataDetail(data);
    }

    return (
        <div>
            {
                isDataDetail
                    ? <DataDetail
                        data={dataDetail}
                        pageIsDataDetail={handlePageIsDataDetail} />
                    : <DataList
                        dataDetail={handleDataDetail}
                        pageIsDataDetail={handlePageIsDataDetail} />
            }
        </div>
    )
}

export default Data;