import { useEffect, useState } from "react";
import InfoBox from "../components/InfoBox";
import { getEquipments } from "../api/request";

function ArchiveList(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [equipments, setEquipments] = useState(null);
    const [info, setInfo] = useState(null);

    useEffect(() => {
        const fetchEquipments = async () => {
            var user = localStorage.getItem("skysan");
            user = JSON.parse(user);
            try {
                const res = await getEquipments(undefined, user._id);
                if (res.status) {
                    setEquipments(res.equipments);
                } else {
                    setInfo("Sayfa yüklenemedi. Tekrar deneyiniz.");
                }
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchEquipments();
    }, []);


    const columns = [
        'PCT',
        'Bölüm',
        'Ekipman Adı',
    ];

    return (
        <div>
            <div className="headerBar">
                <p>Arşiv</p>
            </div>

            {equipments
                ?
                <div className='flex flex-col shadow-lg rounded '>

                    <div className="overflow-auto max-h-screen w-full">
                        <table className="min-w-full overflow-y-auto">
                            <thead
                                className="border-b bg-white text-slate-700 font-medium text-sm sticky top-0">
                                <tr>
                                    {columns.map((column, index) => (
                                        <th scope="col" className="px-3 py-3 text-left whitespace-nowrap" key={index}>{column}</th>
                                    ))}
                                    <th className="px-3 py-3 text-left whitespace-nowrap" ></th>
                                </tr>
                            </thead>
                            <tbody>
                                {equipments.map((row, index) => (
                                    <tr className={`border-b bg-white hover:bg-slate-100 text-sm whitespace-no-wrap`} key={index}>
                                        <td className="px-3 py-3 text-left whitespace-nowrap ">{row.number.number}</td>
                                        <td className="px-3 py-3 text-left whitespace-nowrap">{row.location.location}</td>
                                        <td className="px-3 py-3 text-left whitespace-nowrap">{row.equipmentName === "" ? "--" : row.equipmentName}</td>
                                        <td className="px-3 py-3">
                                            <div
                                                onClick={() => {
                                                    props.archiveDetail(row.number);
                                                    props.pageIsArchiveDetail(true);
                                                }}
                                                className="btn-primary">Detay</div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                isLoading
                    ? <div className="headerBar">
                        <p>Veriler yükleniyor</p>
                    </div>
                    : ""
            }
            {
                info ? <InfoBox content={info} /> : ""
            }
        </div>
    )
}

export default ArchiveList;