import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit, FiPlus } from "react-icons/fi";
import { getLocations } from '../api/request';
import InfoBox from '../components/InfoBox';

function LocationsList(props) {
    const [locations, setLocations] = useState(null);
    const [info, setInfo] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("skysan")));
        const fetchLocations = async () => {
            try {
                const res = await getLocations();
                if (res.status) {
                    setLocations(res.locations);
                } else {
                    setInfo("Sayfa yüklenemedi. Tekrar deneyiniz.");
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchLocations();
    }, []);

    return (
        <div>
            <div className="headerBar">
                <p>Bölümler</p>
                <Link to='/locations/add-location' className='w-max h-max'>
                    <div className='btn-primary'>
                        <FiPlus className='w-5 h-5 mr-1' />
                        <p>Bölüm</p>
                    </div>
                </Link>
            </div>
            <div className='flex flex-col shadow-lg overflow-x-auto rounded'>
                <table>
                    <thead
                        className="border-b bg-white text-slate-700 font-medium text-sm">
                        <tr>
                            <th scope="col" className="w-5 px-6 py-3 text-left" ></th>
                            <th scope="col" className="py-3 text-left">Bölüm</th>
                            <th scope="col" className="py-3 text-left">Aktif</th>
                        </tr>
                    </thead>
                    {locations
                        ?
                        <tbody>
                            {locations.map((row, index) => (
                                <tr className={`border-b bg-white hover:bg-slate-100 text-sm`} key={index}>

                                    {
                                        currentUser.role === "Admin" ?
                                        <td className="px-6 py-3 text-left">
                                            <FiEdit
                                                onClick={() => {
                                                    props.updateLocation(locations[index]);
                                                    props.pageIsUpdateLocation(true);
                                                }}
                                                className='w-4 
                                                    h-4 
                                                    text-emerald-400 
                                                    hover:cursor-pointer
                                                    hover:text-emerald-700' />
                                        </td>
                                        : <td></td>
                                    }

                                    <td className="py-3 text-left">{row.location}</td>
                                    <td className="px-6 py-3 text-left">
                                        <input
                                            type="checkbox"
                                            checked={row.isActive}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        : ""}
                </table>
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default LocationsList;