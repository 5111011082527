import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

function SidebarItem({ title, link, icon: Icon }) {
    const currentPage = useSelector((state) => state.currentPage) || "";
    return (
        <Link to={link}>
            <div className={`
                        h-10
                        font-medium 
                        hover:bg-[#242a38] 
                        rounded-md
                        ${currentPage === title ? "bg-[#242a38] text-blue-500" : "text-[#9ca3af]"} 
                        hover:text-blue-500
                        cursor-pointer
                        flex
                        flex-row
                        items-center
                        mb-1`}>
                <Icon className=" ml-4" />
                <p className="max-lg:hidden ml-3">{title}</p>
            </div>
        </Link>
    )
}

export default SidebarItem;