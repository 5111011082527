import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import AddData from './pages/AddData';
import AddEquipment from './pages/AddEquipment';
import AddLocation from './pages/AddLocation';
import AddNumber from './pages/AddNumber';
import AddUser from './pages/AddUser';
import Archive from './pages/Archive';
import Equipments from './pages/Equipments';
import Data from './pages/Data';
import Documents from './pages/Documents';
import Home from './pages/Home';
import Locations from './pages/Locations';
import Login from './pages/Login';
import Numbers from './pages/Numbers';
import Users from './pages/Users';
import Page404 from './pages/page404';
import Sidebar from './components/Sidebar';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers/rootReducer'
import Admin from './pages/Admin';
import AddCompany from './pages/AddCompany';
import UpdateCompany from './pages/UpdateCompany';

const store = createStore(rootReducer);

const isAuthenticated = () => {
  const auth = localStorage.getItem('skysan');
  return auth !== null;
};

const isRoleUser = () => {
  const user = localStorage.getItem('skysan');
  const userRole = JSON.parse(user).role;
  return userRole === "User";
};

const isRoleMaster = () => {
  const user = localStorage.getItem('skysan');
  const userRole = JSON.parse(user).role;
  return userRole === "Master";
};

const PrivateRoute = ({ element, path }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace state={{ from: path }} />
  }

  const authLinks = [
    "/users",
    "/users/add-user",
    "/locations",
    "/locations/add-location",
    "/pct",
    "/pct/add-pct",
    "/equipments/add-equipment"
  ]

  const masterLinks = [
    "/admin",
    "/admin/add-company",
    "/admin/update-company",
  ]

  const found = authLinks.find((element) => element === path);
  if (isRoleUser() && found) {
    return <Navigate to="/404" replace state={{ from: path }} />
  }

  const foundMaster = masterLinks.find((element) => element === path);
  if (!isRoleMaster() && foundMaster) {
    return <Navigate to="/404" replace state={{ from: path }} />
  }

  return (
    <div className='h-full flex flex-row'>
      {!isRoleMaster() && <Sidebar />}
      <div className='w-full ml-20 lg:ml-64 flex flex-col overflow-x-auto overflow-y-none'>
        <div className="w-full mt-0 p-5 overflow-y-auto">
          {element}
        </div>
      </div>
    </div>
  )
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<PrivateRoute element={<Home />} path="/" />} />
        <Route path="/users" element={<PrivateRoute element={<Users />} path="/" />} />
        <Route path="/users/add-user" element={<PrivateRoute element={<AddUser />} path="/users/add-user" />} />
        <Route path="/equipments" element={<PrivateRoute element={<Equipments />} path="/equipments" />} />
        <Route path="/equipments/add-equipment" element={<PrivateRoute element={<AddEquipment />} path="/equipments/add-equipment" />} />
        <Route path="/data" element={<PrivateRoute element={<Data />} path="/data" />} />
        <Route path="/data/add-data" element={<PrivateRoute element={<AddData />} path="/data/add-data" />} />
        <Route path="/documents" element={<PrivateRoute element={<Documents />} path="/documents" />} />
        <Route path="/locations" element={<PrivateRoute element={<Locations />} path="/locations" />} />
        <Route path="/locations/add-location" element={<PrivateRoute element={<AddLocation />} path="/locations/add-location" />} />
        <Route path="/pct" element={<PrivateRoute element={<Numbers />} path="/pct" />} />
        <Route path="/pct/add-pct" element={<PrivateRoute element={<AddNumber />} path="/pct/add-pct" />} />
        <Route path="/archive" element={<PrivateRoute element={<Archive />} path="/archive" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<PrivateRoute element={<Admin />} path="/admin" />} />
        <Route path="/admin/add-company" element={<PrivateRoute element={<AddCompany />} path="/admin/add-company" />} />
        <Route path="/admin/update-company" element={<PrivateRoute element={<UpdateCompany />} path="/admin/update-company" />} />
        <Route path="*" element={<PrivateRoute element={<Page404 />} path="*" />} />
      </Routes>
    </Provider>
  </BrowserRouter>
);
reportWebVitals();
