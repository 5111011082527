import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import UserList from '../components/UserList';
import UpdateUser from '../components/UpdateUser';

function Users() {
    const title = "Kullanıcılar"
    const dispatch = useDispatch();
    const [isUpdateUser, setIsUpdateUser] = useState(false);
    const [updateUser, setUpdateUser] = useState(false);

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: title });
    }, [dispatch]);

    const handlePageIsUpdateUser = (state) => {
        setIsUpdateUser(state);
    }

    const handleUpdateUser = (user) => {
        setUpdateUser(user);
    }

    return (
        <div>
            {
                isUpdateUser
                    ? <UpdateUser
                        user={updateUser}
                        pageIsUpdateUser={handlePageIsUpdateUser} />
                    : <UserList
                        updateUser={handleUpdateUser}
                        pageIsUpdateUser={handlePageIsUpdateUser}
                    />
            }
        </div>

    )
}

export default Users;