import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ArchiveList from "../components/ArchiveList";
import ArchiveDetail from "../components/ArchiveDetail";

function Archive() {
    const title = "Arşiv"
    const dispatch = useDispatch();
    const [isArchiveDetail, setIsArchiveDetail] = useState(false);
    const [archiveDetail, setArchiveDetail] = useState(false);

    useEffect(() => {
        dispatch({ type: 'CURRENTPAGE', value: title });
    }, [dispatch]);

    const handlePageIsArchiveDetail = (state) => {
        setIsArchiveDetail(state);
    }

    const handleArchiveDetail = (archive) => {
        setArchiveDetail(archive);
    }

    return (
        <div className="h-screen">
            

            {
                isArchiveDetail
                    ? <ArchiveDetail
                        archive={archiveDetail}
                        pageIsArchiveDetail={handlePageIsArchiveDetail}
                         />
                    : <ArchiveList
                        archiveDetail={handleArchiveDetail}
                        pageIsArchiveDetail={handlePageIsArchiveDetail} />
            }

        </div>
    )
}

export default Archive;