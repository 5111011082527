import logo from "../applogo.png";
import { FiDatabase, FiUsers, FiTool, FiArchive } from "react-icons/fi";
// import { FiDatabase, FiHome, FiUsers, FiTool } from "react-icons/fi";
import { TbListNumbers } from "react-icons/tb";
import { FaLocationDot } from "react-icons/fa6";
import { GrDocumentStore } from "react-icons/gr";
import SidebarItem from "./SidebarItem";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

function Sidebar() {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const user = localStorage.getItem("skysan");
        setCurrentUser(JSON.parse(user));
    }, []);

    const turkishToUpper = (str) => {
        const turkishMap = {
            'i': 'İ',
            'ı': 'I',
            'ğ': 'Ğ',
            'ü': 'Ü',
            'ş': 'Ş',
            'ö': 'Ö',
            'ç': 'Ç',
        };
        return str ? str.replace(/[iğıüşöç]/g, match => turkishMap[match]) : str;
    }

    const logout = () => {
        localStorage.removeItem('skysan');
        navigate('/login');
    }

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
    }, []);

    return (
        <div className='w-64 
                        max-lg:w-20
                        h-full 
                        bg-sidebar 
                        flex 
                        flex-col 
                        fixed'>
            <div className="flex flex-col px-4 py-4 justify-center" ref={dropdownRef}>
                <div className="flex flex-row">
                    <img className="w-10 h-10 mr-3" src={logo} alt="Logo" />
                    <p className="font-bold text-3xl text-[#024d75] max-lg:hidden">SKY<span className="text-[#642e8c]">SAN</span></p>
                </div>

                {
                    currentUser
                        ? <div
                            className="bg-[#192130] mt-4 px-4 py-2 rounded-md cursor-pointer"
                            onClick={() => {
                                setIsOpen(!isOpen);
                            }}>
                            <div>
                                <div className="max-lg:hidden">
                                    <p className="text-white text-sm">{currentUser.name} {currentUser.surname}</p>
                                    <p className="text-[#939aa6] text-sm">{currentUser.role}</p>
                                </div>
                                <p className="text-[#939aa6] font-medium text-lg lg:hidden flex justify-center">
                                    {turkishToUpper(currentUser.name).toUpperCase().charAt(0)}
                                </p>
                                {isOpen && (
                                    <div
                                        className="w-56 absolute left-4 mt-2 mr-4 space-y-2 origin-top-right bg-white border border-gray-300 
                                        rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50 max-h-52 overflow-y-auto"
                                        role="menu"
                                        aria-orientation="vertical"
                                        aria-labelledby="options-menu"
                                    >
                                        <button
                                            onClick={logout}
                                            className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                            role="menuitem">
                                            Çıkış
                                        </button>

                                    </div>
                                )}
                            </div>
                        </div>
                        : ""
                }
            </div>

            <div className="h-full flex justify-between flex-col">
                <div className='flex flex-col px-4 '>
                    {/* <SidebarItem title={"Ana sayfa"} link={"/"} icon={FiHome} /> */}
                    {
                        currentUser?.role === "Admin" &&
                        <>
                            <SidebarItem title={"Kullanıcılar"} link={"/users"} icon={FiUsers} />
                            <SidebarItem title={"PCT"} link={"/pct"} icon={TbListNumbers} />
                            <SidebarItem title={"Bölümler"} link={"/locations"} icon={FaLocationDot} />

                        </>
                    }

                    <SidebarItem title={"Ekipmanlar"} link={"/equipments"} icon={FiTool} />
                    <SidebarItem title={"Kayıtlar"} link={"/data"} icon={FiDatabase} />
                    <SidebarItem title={"MMO Dökümanlar"} link={"/documents"} icon={GrDocumentStore} />
                    <SidebarItem title={"Arşiv"} link={"/archive"} icon={FiArchive} />
                </div>

            </div>

        </div>
    )
}

export default Sidebar;