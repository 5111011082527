import { useState } from "react";
import { postInsertNumber } from "../api/request";
import { FiChevronLeft } from "react-icons/fi";
import InfoBox from "../components/InfoBox";
import { useNavigate } from "react-router-dom";

function AddNumber() {
    const [formData, setFormData] = useState({
        number: ""
    });
    const [info, setInfo] = useState(null);
    const navigate = useNavigate();


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await postInsertNumber(formData);
            setInfo(res.message);
            setTimeout(() => {
                setInfo(null);
            }, 2000);
        } catch (error) {

        }
    };

    return (
        <div>
            <div className="headerBar">
                <p>Periyodik Kontrol Etiketi Ekle</p>
                <div
                    onClick={() => {
                        navigate("/pct");
                    }}
                    className='px-2 py-1 rounded-md bg-blue-500 hover:cursor-pointer hover:bg-blue-600 text-white flex flex-row items-center'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>PCT</p>
                </div>
            </div>
            <div className="w-full 
                        bg-white
                        shadow-lg
                        rounded
                        p-5">
                <form
                    onSubmit={handleSubmit}
                    className='w-full flex flex-col'>
                    <div className='w-full flex flex-col md:flex-row'>
                        <div className='w-full md:w-80 mr-0 md:mr-5'>
                            <div className='flex flex-col mb-6'>
                                <label className='text-slate-600 font-medium mb-1'>PCT</label>
                                <input
                                    type="text"
                                    name="number"
                                    className='w-full h-10 border border-gray-300 rounded-md px-3 py-4 
                                        text-sm font-medium text-left text-gray-700 outline-none'
                                    onChange={handleChange}
                                    value={formData.number}
                                    required />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className='btn-secondary'>
                        Kaydet
                    </button>
                </form>
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default AddNumber;