import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiEdit, FiTrash, FiPlus } from "react-icons/fi";
import { deleteUser, getUsers } from '../api/request';
import InfoBox from '../components/InfoBox';

function UserList(props) {
    const [users, setUsers] = useState(null);
    const [info, setInfo] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("skysan")));
        const fetchUsers = async () => {
            try {
                const res = await getUsers();
                if (res.status) {
                    setUsers(res.users);
                } else {
                    setInfo("Sayfa yüklenemedi. Tekrar deneyiniz.");
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchUsers();
    }, []);

    const columns = ['Ad', 'Soyad', 'E-posta', "Rol", "Kullanıcı adı", "Aktif"];

    const handleDeleteUser = async (userID) => {
        const isConfirmed = window.confirm("Kullanıcı Silmek İstediğinize Emin misiniz?");
        if (isConfirmed) {
            try {
                const res = await deleteUser(userID);
                if (res.status) {
                    setInfo(res.message);
                    setUsers((prev) => prev.filter(item => item._id !== userID));
                } else {
                    setInfo("Kullanıcı silinemedi.")
                }
                setTimeout(() => {
                    setInfo(null);
                }, 2000);
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <div>
            <div className="headerBar">
                <p>Kullanıcılar</p>
                <Link to='/users/add-user' className='w-max h-max'>
                    <div className='btn-primary'>
                        <FiPlus className='w-5 h-5 mr-1' />
                        <p>Kullanıcı</p>
                    </div>
                </Link>
            </div>
            <div className='flex flex-col shadow-lg overflow-x-auto rounded'>
                <table>
                    <thead
                        className="border-b bg-white text-slate-700 font-medium text-sm">
                        <tr>
                            <th scope="col" className='pl-4'></th>
                            {columns.map((column, index) => (
                                <th scope="col" className="px-6 py-3 text-left" key={index}>{column}</th>
                            ))}
                        </tr>
                    </thead>
                    {users
                        ?
                        <tbody>
                            {users.map((row, index) => (
                                <tr className={`border-b bg-white hover:bg-slate-100 text-sm`} key={index}>
                                    <td className="w-5 pl-6 py-4">
                                        <div className='flex flex-row'>
                                            <div className='mr-6'>
                                                <FiEdit
                                                    onClick={() => {
                                                        props.updateUser(users[index]);
                                                        props.pageIsUpdateUser(true);
                                                    }}
                                                    className='w-4 
                                                    h-4 
                                                    text-emerald-400 
                                                    hover:cursor-pointer
                                                    hover:text-emerald-700' />
                                            </div>
                                            {
                                                currentUser?.role === "Admin" &&
                                                <div>
                                                    <FiTrash
                                                        onClick={() => {
                                                            handleDeleteUser(users[index]._id)
                                                        }}
                                                        className='w-4 
                                                                h-4 
                                                                text-red-400 
                                                                hover:cursor-pointer
                                                                hover:text-red-700' />
                                                </div >

                                            }

                                        </div>

                                    </td>

                                    <td className="px-6 py-3 text-left">{row.name}</td>
                                    <td className="px-6 py-3 text-left">{row.surname}</td>
                                    <td className="px-6 py-3 text-left">{row.email}</td>
                                    <td className="px-6 py-3 text-left">{row.role}</td>
                                    <td className="px-6 py-3 text-left">{row.username}</td>
                                    <td className="px-6 py-3 text-left">
                                        <input
                                            type="checkbox"
                                            checked={row.isActive}
                                            readOnly
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        : ""}
                </table>
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default UserList;