import { useEffect, useState } from "react";
import { FiDownload, FiEdit, FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getEquipments, getLocations } from "../api/request";
import InfoBox from "./InfoBox";
import DropdownMenu from "./DropdownMenu";
import { URL } from "../utils/utils";
import axios from "axios";

function EquipmentList(props) {
    const [equipments, setEquipments] = useState(null);
    const [info, setInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [locations, setLocations] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(undefined);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        const fetchLocations = async () => {
            var user = localStorage.getItem("skysan");
            user = JSON.parse(user);
            setCurrentUser(user);
            try {
                const res = await getLocations(user._id);
                if (res.status) {
                    setLocations(res.locations.filter(item => item.isActive));
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchLocations();
    }, []);

    useEffect(() => {
        const fetchEquipments = async () => {
            try {
                const res = await getEquipments(selectedLocation, currentUser._id);
                if (res.status) {
                    const equipmentSort = await res.equipments.sort((a, b) => {
                        var numA = parseInt(a.number.number.match(/\d+/)[0]);
                        var numB = parseInt(b.number.number.match(/\d+/)[0]);
                        return numA - numB;
                    });
                    setEquipments(equipmentSort);
                } else {
                    setInfo("Sayfa yüklenemedi. Tekrar deneyiniz.");
                }
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetchEquipments();
    }, [selectedLocation, currentUser?._id]);

    const columns = [
        'PCT',
        'Bölüm',
        "Ekipman Kategori",
        'Ekipman Adı',
        "Marka (Model)",
        "İmalat Yılı",
        "Seri No",
        "Kapasite",
        "Çalışma Basıncı (bar)",
        // "Adet",
        "SAP Ekipman No",
        "Bunge Varlık Etiket No",
        "Kotrol için Üretim Duruşu Gerekli Mi?",
        "Kontrol Periyodu (gün)"
    ];

    const handleDownload = async () => {
        try {
            const response = await axios.get(`${URL}api/download/download-excel`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Ekipmanlar.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Hata:', error);
        }
    };

    return (
        <div>
            <div className="headerBar">
                <p>Ekipmanlar</p>
                <div className="flex flex-row">
                    <div className='btn-download mr-3'
                        onClick={handleDownload}>
                        <FiDownload className='w-5 h-5 mr-1' />
                        <p>İndir</p>
                    </div>
                    {
                        currentUser?.role === "Admin" &&
                        <Link to='/equipments/add-equipment' className='w-max h-max'>
                            <div className='btn-primary'>
                                <FiPlus className='w-5 h-5 mr-1' />
                                <p>Ekipman</p>
                            </div>
                        </Link>
                    }

                </div>
            </div>
            <div className="flex flex-row">
                {
                    locations &&
                    <div className="flex flex-col">
                        <p className="text-slate-700 font-medium text-sm">Bölüm</p>
                        <div className="w-64 mb-4 mr-3">
                            <DropdownMenu
                                selection="Hepsi"
                                selectedIndexNo={(index) => {
                                    setSelectedLocation(index === 0 ? undefined : locations[index - 1]._id);
                                }}
                                options={["Hepsi", ...locations.map(item => item.location)]} />
                        </div>
                    </div>
                }
            </div>
            {equipments
                ?
                <div className='flex flex-col shadow-lg rounded '>

                    <div className="overflow-auto max-h-screen w-full">
                        <table className="min-w-full overflow-y-auto">
                            <thead
                                className="border-b bg-white text-slate-700 font-medium text-sm sticky top-0">
                                <tr>
                                    <th scope="col" className='pl-4 py-3 whitespace-nowrap'>

                                    </th>
                                    {columns.map((column, index) => (
                                        <th scope="col" className="px-2 py-3 text-left whitespace-nowrap" key={index}>{column}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {equipments.map((row, index) => (
                                    <tr className={`border-b bg-white hover:bg-slate-100 text-sm whitespace-no-wrap`} key={index}>
                                        <td className="w-5 px-6 py-4 whitespace-nowrap">
                                            <FiEdit
                                                onClick={() => {
                                                    props.updateEquipment(equipments[index]);
                                                    props.pageIsUpdateEquipment(true);
                                                }}
                                                className='w-4 
                                                    h-4 
                                                    text-emerald-400 
                                                    hover:cursor-pointer
                                                    hover:text-emerald-700' />
                                        </td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap ">{row.number.number}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.location.location}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.equipmentCategory === "" ? "--" : row.equipmentCategory}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.equipmentName === "" ? "--" : row.equipmentName}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.brand === "" ? "--" : row.brand}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.manufactureYear === "" ? "--" : row.manufactureYear}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.serialNo === "" ? "--" : row.serialNo}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">
                                            {row.capacity === "" ? "--" : row.capacity}
                                            {row.capacityType === "" ? "" : " " + row.capacityType}
                                        </td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.workingPressure === "" ? "--" : row.workingPressure}</td>
                                        {/* <td className="px-2 py-3 text-left whitespace-nowrap">{row.piece === "" ? "--" : row.piece}</td> */}
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.SAPEquipmentNo === "" ? "--" : row.SAPEquipmentNo}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.assetLabelNo === "" ? "--" : row.assetLabelNo}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.producionStopIsItNecessary === "" ? "--" : row.producionStopIsItNecessary}</td>
                                        <td className="px-2 py-3 text-left whitespace-nowrap">{row.controlPeriod === "" ? "--" : row.controlPeriod}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                :
                isLoading
                    ? <div className="headerBar">
                        <p>Veriler yükleniyor</p>
                    </div>
                    : ""
            }
            {
                info ? <InfoBox content={info} /> : ""
            }
        </div>
    )
}

export default EquipmentList;