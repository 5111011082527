import { useEffect, useState } from "react";
import { deleteEquipment, deleteFile, getFilesByEquipmentID, getSizeControl } from "../api/request";
import InfoBox from "../components/InfoBox";
import FormEquipment from "./FormEquipment";
import { FiChevronLeft, FiX } from "react-icons/fi";
import PDFLogo from "../pdf.png";
import EXCELLogo from "../excel.png";
import WORDLogo from "../word.png";
import { URL } from "../utils/utils";
import axios from "axios";
import Cookies from "js-cookie";

function UpdateEquipment({ equipment, pageIsUpdateEquipment }) {
    const [info, setInfo] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [isImageFocus, setIsImageFocus] = useState(false);
    const [imageFocus, setImageFocus] = useState(null);

    const [currentUser, setCurrentUser] = useState(null);

    useState(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("skysan")));
        const fetchFile = async () => {
            try {
                const res = await getFilesByEquipmentID(equipment._id);
                if (res.status) {
                    setFileList(res.files);
                }
            } catch (error) {
                console.error('Hata:', error);
            }
        }
        fetchFile();
    }, []);

    const handleUpdateEquipment = async (newEquipment, number) => {
        try {
            const res = await getSizeControl(number.number);
            if (!res.status) {
                setInfo(res.message);
                return;
            }
        } catch (error) {
            console.log(error);
        }

        try {
            const config = {
                headers: {
                    authorization: `Bearer ${Cookies.get('skysan-token')}`
                }
            };

            const res = await axios.post(`${URL}api/equipments/update-equipment`, newEquipment, config);
            if (res.data.status) {
                setInfo(res.data.message);
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDeleteFile = async (fileID) => {
        const isConfirmed = window.confirm("Belge Silmek İstediğinize Emin misiniz?");
        if (isConfirmed) {
            try {
                const res = await deleteFile(fileID);
                if (res.status) {
                    setInfo(res.message);
                    setFileList((prev) => prev.filter(item => item._id !== fileID));
                } else {
                    setInfo("Belge silinemedi.")
                }
                setTimeout(() => {
                    setInfo(null);
                }, 2000);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleDeleteEquipment = async () => {
        const isConfirmed = window.confirm("Ekipman ve İlgili Kayıtlar Silinecek. Emin misiniz?");
        if (isConfirmed) {
            try {
                setInfo("Siliniyor...");
                const res = await deleteEquipment(equipment._id);
                if (res.status) {
                    pageIsUpdateEquipment(false);
                } else {
                    setInfo("Belge silinemedi.")
                }
                setTimeout(() => {
                    setInfo(null);
                }, 2000);
            } catch (error) {
                console.log(error);
            }
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            // ESC
            if (event.keyCode === 27) {
                setIsImageFocus(false);
                setImageFocus(null);
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div>
            {
                isImageFocus
                    ? <div className="w-full h-full bg-[#00000066] z-50 absolute top-0 left-0 flex items-center justify-center flex-row">
                        <img
                            src={`${URL}uploads/${imageFocus}`}
                            className="w-auto h-5/6"
                            alt={`${imageFocus}`}
                        />
                        <FiX
                            className="w-7 h-7 absolute top-4 right-4 cursor-pointer"
                            onClick={() => {
                                setIsImageFocus(false);
                                setImageFocus(null);
                            }} />

                    </div>
                    : ""
            }
            <div className="headerBar">
                <p>Ekipman güncelle</p>
                <div
                    onClick={() => {
                        pageIsUpdateEquipment(false);
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Ekipmanlar</p>
                </div>
            </div>

            <div className="w-full 
                        bg-white
                        shadow-lg
                        rounded
                        p-5">
                <FormEquipment
                    buttonTitle={"Güncelle"}
                    onToggle={handleUpdateEquipment}
                    equipment={equipment}
                    isVisible={false} />

                <div>
                    {
                        fileList.length !== 0 &&
                        <p className="text-slate-700 font-bold text-sm mr-2 mt-2">Ekipman</p>
                    }
                    <div className="flex flex-row flex-wrap">

                        {fileList.map((file, index) => {
                            return (() => {
                                switch (file.fileType) {
                                    case "image":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-32">
                                                    <img
                                                        key={index}
                                                        src={`${URL}uploads/${file.path}`}
                                                        alt={`${index}`}
                                                        className="w-24 h-24 cursor-pointer"
                                                        onClick={() => {
                                                            setIsImageFocus(true);
                                                            setImageFocus(file.path);
                                                        }}
                                                    />
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>

                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    case "pdf":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-28">
                                                    <a href={`${URL}uploads/${file.path}`} target="blank">
                                                        <img
                                                            key={index}
                                                            src={PDFLogo}
                                                            alt={`${index}`}
                                                            className="w-24 h-24 cursor-pointer"
                                                        />
                                                    </a>
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>
                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    case "excel":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-28">
                                                    <a href={`${URL}uploads/${file.path}`} target="blank">
                                                        <img
                                                            key={index}
                                                            src={EXCELLogo}
                                                            alt={`${index}`}
                                                            className="w-24 h-24 cursor-pointer"
                                                        />
                                                    </a>
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>
                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    case "word":
                                        return (
                                            <div key={index} className="w-24 h-28 m-1.5 flex flex-col group">
                                                <div className="w-24 h-28">
                                                    <a href={`${URL}uploads/${file.path}`} target="blank">
                                                        <img
                                                            key={index}
                                                            src={WORDLogo}
                                                            alt={`${index}`}
                                                            className="w-24 h-24 cursor-pointer"
                                                        />
                                                    </a>
                                                    <div className="w-7 h-7 -mt-24 flex items-center justify-center">
                                                        <FiX
                                                            className="w-5 h-5 invisible group-hover:visible cursor-pointer"
                                                            onClick={() => handleDeleteFile(file._id)} />
                                                    </div>
                                                </div>

                                                <p className="text-sm truncate flex items-center">{file.filename}</p>
                                            </div>
                                        );
                                    default:
                                        return null;
                                }
                            })()
                        })}
                    </div>
                    <div className="flex justify-end">
                        {
                            currentUser?.role === "Admin" &&
                            <button
                                className="btn-delete"
                                onClick={handleDeleteEquipment}
                            >Sil</button>
                        }
                    </div>

                </div>
                {
                    info ? <InfoBox content={info} /> : ""
                }

            </div>
        </div>
    )
}

export default UpdateEquipment;