import { useState } from "react";
import logo from "../applogo.png"
import { getLogin } from "../api/request";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { URL } from "../utils/utils";
import Cookies from 'js-cookie';

function Login() {
    const navigate = useNavigate();
    const [message, setMessage] = useState(null);
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });

    const handleChange = (e) => {
        if (e.target.value.includes('&')) {
            return;
        }
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const login = async () => {
        try {
            const res = await getLogin(formData);
            if (res.status) {
                localStorage.setItem("skysan", JSON.stringify(res.user));
                Cookies.set('skysan-token', res.token, { expires: 1 });
                if (res.user.role === "Master") return navigate("/admin");

                navigate("/equipments");

            } else {
                setMessage(res.message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const downloadApp = async () => {
        try {
            const response =
                await axios.get(`${URL}api/app/download`, {
                    responseType: 'blob',
                });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Skysan.apk');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Hata:', error);
        }
    }

    return (
        <div className="w-screen h-screen bg-login bg-no-repeat bg-cover flex justify-center items-center">
            <div className="w-full h-full overflow-auto flex justify-center items-center">
                <div className="flex flex-col items-center bg-white/30 backdrop-blur-md p-10">
                    <img className="w-24 h-24 mb-10" src={logo} alt={"logo"} />
                    <p className="font-medium mb-5">Oturum aç</p>
                    <input
                        type="text"
                        name="email"
                        className='w-80 h-10 border border-gray-300 px-4 my-2
                            text-sm text-left text-gray-700 outline-none bg-white/80'
                        placeholder='E posta'
                        onChange={handleChange}
                        value={formData.email}
                        required
                    />
                    <input
                        type="password"
                        name="password"
                        className='w-80 h-10 border border-gray-300 px-4 my-2
                            text-sm text-left text-gray-700 outline-none bg-white/80'
                        placeholder='Şifre'
                        onChange={handleChange}
                        value={formData.password}
                        required
                    />
                    {
                        message
                            ? <p className="text-red-500 font-medium">{message}</p>
                            : ""
                    }
                    <button
                        onClick={login}
                        className="w-full h-10 rounded-md bg-blue-500 
                                hover:cursor-pointer hover:bg-blue-600 
                                text-white flex flex-row items-center mt-5
                                flex justify-center text-center">
                        Giriş
                    </button>
                    <p className="text-sm mt-5">
                        Uygulamayı yüklemek için
                        <span
                            className="font-medium cursor-pointer ml-1"
                            onClick={downloadApp}>
                            tıklayın.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Login;