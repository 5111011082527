import { useState } from "react";
import { postUpdateUser } from "../api/request";
import FormUser from "../components/FormUser";
import InfoBox from "../components/InfoBox";
import { FiChevronLeft } from "react-icons/fi";

function UpdateUser({ user, pageIsUpdateUser }) {
    const [info, setInfo] = useState(null);

    const handleUpdatetUser = async (user, userLocations) => {
        try {
            if (user.password) {
                const passwordError = validatePassword(user.password);
                if (passwordError) {
                    setInfo(passwordError);
                    return;
                }
            }

            const res = await postUpdateUser({ user, userLocations });
            if (res.status) {
                setInfo(res.message);
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const validatePassword = (password) => {
        const minLength = password.length >= 8;
        const hasUpperCase = /[A-Z]/.test(password);

        if (!minLength) {
            return "Şifre en az 8 karakter olmalıdır";
        }
        if (!hasUpperCase) {
            return "Şifre en az bir büyük harf içermelidir";
        }

        return "";
    };

    return (
        <div>
            <div className="headerBar">
                <p>Kullanıcı güncelle</p>
                <div
                    onClick={() => {
                        pageIsUpdateUser(false);
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Kullanıcılar</p>
                </div>
            </div>

            <div className="w-full 
                        bg-white
                        shadow-lg
                        rounded
                        p-5">
                <FormUser
                    buttonTitle={"Güncelle"}
                    onToggle={handleUpdatetUser}
                    user={user} />
                {
                    info ? <InfoBox content={info} /> : ""
                }

            </div>
        </div>
    )
}

export default UpdateUser;