import React, { useEffect, useRef, useState } from "react";

function MultipleDropdown(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [placeholder, setPlaceholder] = useState();
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        props.onToggle(props.name, selectedItems);
        // eslint-disable-next-line
    }, [selectedItems]);

    const handleItemClick = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter(x => x !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
        setIsOpen(false);
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (props.placeholder === "Choose") {
            setSelectedItems([]);
        }
        setPlaceholder(props.placeholder);
    }, [props.placeholder]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
    }, []);

    return (
        <div className="relative inline-block w-full" ref={dropdownRef}>
            <label className="block mb-2 mt-4 text-sm font-medium text-gray-700">{props.title}</label>

            <div
                onClick={toggleDropdown}
                className="bg-white border focus:outline-none flex justify-between items-center cursor-pointer focus:ring-2 focus:ring-blue-500 py-2 px-4 rounded-lg w-full text-left">
                {placeholder}
                <i className='bx bxs-chevron-down float-right'></i>
            </div>

            {isOpen && (
                <ul className="absolute left-0 right-0 max-h-64 overflow-auto mt-1 bg-white border border-gray-300 rounded shadow-md z-10">
                    {props.data.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => handleItemClick(item)}
                            className={`${selectedItems.includes(item) && "bg-gray-200"} cursor-pointer hover:bg-gray-100 py-2 px-4`}>
                            {item}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default MultipleDropdown;
