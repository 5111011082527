import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function Home() {
  const title = "Ana sayfa"
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: 'CURRENTPAGE', value: title });
  }, [dispatch]);

  useEffect(() => {
    navigate("/equipments");
  }, [navigate]);

  return (
    <div>
    </div>
  );
}

export default Home;
