import { useState } from "react";
import { postInsertUser } from "../api/request";
import { FiChevronLeft } from "react-icons/fi";
import FormUser from "../components/FormUser";
import InfoBox from "../components/InfoBox";
import { useNavigate } from "react-router-dom";

function AddUser() {
    const [info, setInfo] = useState(null);
    const navigate = useNavigate();

    const handleInsertUser = async (newUser, userLocations) => {
        var user = localStorage.getItem("skysan");
        user = JSON.parse(user);
        newUser.companyID = user.companyID;

        try {
            if (newUser.password) {
                const passwordError = validatePassword(newUser.password);
                if (passwordError) {
                    setInfo(passwordError);
                    return;
                }
            }

            const res = await postInsertUser({ user: newUser, userLocations });
            if (res.status) {
                setInfo(res.message);
            } else {
                setInfo("İşlem başarısız.");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const validatePassword = (password) => {
        const minLength = password.length >= 8;
        const hasUpperCase = /[A-Z]/.test(password);

        if (!minLength) {
            return "Şifre en az 8 karakter olmalıdır";
        }
        if (!hasUpperCase) {
            return "Şifre en az bir büyük harf içermelidir";
        }

        return "";
    };

    return (
        <div>
            <div className="headerBar">
                <p>Kullanıcı ekle</p>
                <div
                    onClick={() => {
                        navigate("/users");
                    }}
                    className='btn-primary'>
                    <FiChevronLeft className='w-5 h-5 mr-1' />
                    <p>Kullanıcılar</p>
                </div>
            </div>
            <div className="w-full 
                        bg-white
                        shadow-lg
                        rounded
                        p-5">
                <FormUser
                    buttonTitle={"Kaydet"}
                    onToggle={handleInsertUser}
                    user={{
                        name: "",
                        surname: "",
                        email: "",
                        role: "Admin",
                        username: "",
                        isActive: true,
                        password: "",
                        authority: [],
                    }} />
                {
                    info ? <InfoBox content={info} /> : ""
                }
            </div>
        </div>
    )
}

export default AddUser;